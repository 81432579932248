import React from "react";
import { styled } from "@mui/system";
import { useAppSelector } from "../../../store";
import WelcomeMessage from "./WelcomeMessage";
import ChatDetails from "./ChatDetails";

const MainContainer = styled("div")({
  
});

const Messenger = () => {
  const chosenChatDetails = useAppSelector((state) => state.chat.chosenChatDetails);

  return (
    <>
      <div className="user-chat w-100 overflow-hidden">
        
          <div className="user-chat-overlay" id="user-chat-overlay"></div>
          {chosenChatDetails?.userId ?(<ChatDetails />)
            : (<WelcomeMessage />)}
        </div>
      
    </>
  )
};

export default Messenger;
