import React, { useState } from "react";
import { styled } from "@mui/system";
import FriendsListItem from "./FriendsListItem";
import { useAppSelector } from "../../../../store";

import Loader from "../../../../components/Loader";
import AppSimpleBar from "../../../../components/AppSimpleBar";
import { UncontrolledTooltip } from "reactstrap";
import AddButton from "../../../../components/AddButton";
/* import AppSimpleBar from "../../../../components/AppSimpleBar";
import classnames from "classnames";

import { DivideByKeyResultTypes } from "../../../../utils";

import { ContactTypes } from "../../../../data/contacts";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"; */


const MainContainer = styled("div")({
  flexGrow: 1,
  width: "100%",
});


const FriendsList = () => {
  const { friends, onlineUsers } = useAppSelector(state => state.friends);

  const modifiedFriends = friends.map(friend => {
    const isOnline = onlineUsers.find(user => user.userId === friend.id);

    return { ...friend, isOnline: !!isOnline };
  })
  return (
    <>
        {modifiedFriends.map((f) => (
          <li >
          <FriendsListItem
            username={f.username}
            id={f.id}
            key={f.id}
            isOnline={f.isOnline}
            email={f.email}
          /></li>
        ))}
     
    </>
  );
};

export default FriendsList;
