import React, { useRef, useState } from "react";
import { styled } from "@mui/system";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import IconButton from "@mui/material/IconButton";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import Avatar from "../../../../components/Avatar";
import { useAppSelector } from "../../../../store";
import { callRequest } from "../../../../socket/socketConnection";
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
 
  Alert,
  UncontrolledTooltip,
} from "reactstrap";
import VideoCallModal from "../../../../components/VideoCallModal";
import { sassTrue } from "sass";
//
//const jquery = require('jquery');

/* const MainContainer = styled("div")({

}); */

interface MoreProps {
  onOpenAudio: () => void;
  onOpenVideo: () => void;
  onDelete: () => void;
  //isArchive: boolean;
  onToggleArchive: () => void;
}



const ModalVideo=(x:boolean)=>{
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  
  setModal(x);

  return (
    <Modal style={{zIndex:"10000"}} isOpen={modal} toggle={toggle} >
          <ModalHeader toggle={toggle}>Modal title</ModalHeader>
          <ModalBody>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle}>Do Something</Button>{' '}
            <Button color="secondary" onClick={toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
  )
}

const More = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle color="none" className="btn nav-btn" type="button">
        <i className="bx bx-dots-vertical-rounded"></i>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem
          className="d-flex justify-content-between align-items-center d-lg-none user-profile-show"
          to="#"
        >
          View Profile <i className="bx bx-user text-muted"></i>
        </DropdownItem>
        <DropdownItem
          className="d-flex justify-content-between align-items-center d-lg-none"
          to="#"
        // onClick={onOpenAudio}
        >
          Audio <i className="bx bxs-phone-call text-muted"></i>
        </DropdownItem>
        <DropdownItem
          className="d-flex justify-content-between align-items-center d-lg-none"
          to="#"
        // onClick={onOpenVideo}
        >
          Video <i className="bx bx-video text-muted"></i>
        </DropdownItem>
        <DropdownItem
          className="d-flex justify-content-between align-items-center"
          to="#"
        // onClick={onToggleArchive}
        >
          {/* {isArchive ? (
              <>
                Un-Archive <i className="bx bx-archive-out text-muted"></i>
              </>
            ) : (
              <>
                Archive <i className="bx bx-archive text-muted"></i>
              </>
            )} */}
        </DropdownItem>
        <DropdownItem
          className="d-flex justify-content-between align-items-center"
          to="#"
        >
          Muted <i className="bx bx-microphone-off text-muted"></i>
        </DropdownItem>
        <DropdownItem
          className="d-flex justify-content-between align-items-center"
          to="#"
        // onClick={onDelete}
        >
          Delete <i className="bx bx-trash text-muted"></i>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};


/* const CallButtons = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
})
 */
const MessagesHeader: React.FC<{
  scrollPosition: number;
}> = ({ scrollPosition }) => {
const handleModel=()=>{
  ModalVideo(true);
}
  const navRef = useRef<HTMLDivElement>(null);
  let navPosition = navRef.current?.getBoundingClientRect().top;


  const { auth: { userDetails }, chat: { chosenChatDetails } } = useAppSelector((state) => state);

  const navActiveStyle = scrollPosition >= navPosition! ? { backgroundColor: "#F2F2F2" } : { backgroundColor: "transparent" };
 
  const [user, setUser] = useState(null || "ghada");
  const [isOpenVideoModal, setIsOpenVideoModal] = useState<boolean>(false);
 /*  const onOpenVideo = () => {
    setUser("ghada");
    setIsOpenVideoModal(true);
  }; */
  const onCloseVideo = () => {
    //setUser(null);
    setIsOpenVideoModal(false);
    console.log("close")
  };

  /* const [isOpenVideoModal, setIsOpenVideoModal] = useState<boolean>(false); */
  const onOpenVideo = () => {
    setIsOpenVideoModal(true);
  };
//const videoModal=document.getElementById("videoModal");
  return (
    <>
      <div className="p-3 p-lg-4 user-chat-topbar  "  >
        {/* style={{ 
    backgroundImage: `url(${".\pattern-05.png"})`}} */}
        <div className="align-items-center row">
          <div className="col-8 col-sm-4">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 d-block d-lg-none me-2">
                <a className="user-chat-remove text-muted font-size-24 p-2" href="/dashboard">
                  <i className="bx bx-chevron-left align-middle"></i></a></div>
              <div className="flex-grow-1 overflow-hidden">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 chat-user-img align-self-center me-3 ms-0 online">
                    <Avatar username={chosenChatDetails?.username!} />
                    <span className="user-status bg-success"></span></div>
                  <div className="flex-grow-1 overflow-hidden">
                    <h6 className="text-truncate mb-0 font-size-18">
                      <a className="user-profile-show text-reset" href="/dashboard">{chosenChatDetails?.username}</a></h6>
                    <p className="text-truncate text-muted mb-0">
                      <small>Active</small></p></div></div></div></div></div>
          <div className="col-4 col-sm-8">
            <ul className="list-inline user-chat-nav text-end mb-0">
              <li className="list-inline-item">
                <div className="dropdown">
                  <button type="button" aria-haspopup="true" aria-expanded="false" className="btn nav-btn dropdown-toggle btn btn-none">
                    <i className="bx bx-search"></i>
                  </button>
                  <div role="menu" aria-hidden="true" className="dropdown-menu p-0 dropdown-menu-end dropdown-menu-lg dropdown-menu">
                    <div className="search-box p-2">
                      <input placeholder="Search.." type="text" className="form-control form-control"></input></div></div></div></li>
              <li className="list-inline-item d-none d-lg-inline-block me-2 ms-0">

                <IconButton
                  style={{ color: "#797C8C" }}
                  onClick={() => {
                    callRequest({
                      audioOnly: true,
                      callerName: "token" in userDetails ? userDetails.username : "",
                      receiverUserId: chosenChatDetails?.userId!,
                    })
                  }}
                >
                  <AddIcCallIcon />
                </IconButton></li>
              <li className="list-inline-item d-none d-lg-inline-block me-2 ms-0">
                <IconButton
                  style={{ color: "#797C8C" }}
                  onClick={() => { callRequest({
                      audioOnly: false,
                      callerName:
                        "token" in userDetails
                          ? userDetails.username
                          : "",
                      receiverUserId: chosenChatDetails?.userId!,
                    }); } } >

                
               
                 <VideoCallIcon /> 
                </IconButton>
              </li>
              <li className="list-inline-item d-none d-lg-inline-block me-2 ms-0">
                <button
                  //onClick={onOpenUserDetails}
                  type="button"
                  className="btn nav-btn user-profile-show"
                >
                  <i className="bx bxs-info-circle"></i>
                </button>
              </li>

              <li className="list-inline-item">
                <More />
              </li>
            </ul>
          </div>
        </div>
      </div>


    </>
  );
};

export default MessagesHeader;
