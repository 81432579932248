import React from "react";
import { styled } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import Camera from "./Camera";
import Microphone from "./Microphone";
import CloseRoom from "./CloseRoom";
import ScreenShare from "./ScreenShare";
import { useAppSelector } from "../../../store"
import Avatar from "../../Avatar";
import Video from "../Video";

const MainContainer = styled("div")({
    height: "15%",
    width: "40%",
    backgroundColor: "#4EAC6D",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",

    position: "relative",
    top: "4%",
    zIndex: "1000000000"
});

const RoomButtons: React.FC<{
    isRoomMinimized: boolean;
    user:string;
}> = ({ isRoomMinimized,user }) => {
    const videoChat = useAppSelector((state) => state.videoChat);
    const matches = useMediaQuery("(max-width:800px)");

    if (!videoChat.localStream) {
        return null;
    }
 
    return (
        <MainContainer
            sx={{
                ...(matches &&
                    isRoomMinimized && {
                    height: "100%",
                    width: "15%",
                    flexDirection: "column",
                }),
            }}
        >
            <div className="videocallModal-bg">
                
                </div>
           
            <div className="position-absolute start-0 end-0 bottom-0"></div>
            <div className="position-absolute start-0 end-0 bottom-0">
                <div className="text-center">
                <div className="d-flex justify-content-center align-items-center text-center">
                    {!videoChat.audioOnly && (
                        <>

                            <Camera localStream={videoChat.localStream} />
                        </>
                    )}
                    <ScreenShare videoChat={videoChat} />
                    <Microphone localStream={videoChat.localStream} />
                </div>
                <CloseRoom />
            </div>
            <div className="p-4 bg-primary mt-n4">
            <div className="text-white mt-4 text-center">
              <h5 className="font-size-18 text-truncate mb-0 text-white">
               {user}
              </h5>
            </div>
          </div></div>
        </MainContainer>
    );
};


export default RoomButtons

