import React from "react";
import { styled } from "@mui/system";
import PendingInvitationsListItem from "./PendingInvitationsListItem";
import { useAppSelector } from "../../../../store";

const DUMMY_INVITATIONS = [
  {
    _id: "1",
    senderId: {
      username: "Mark",
      email: "dummy@ad.com",
    },
  },
  {
    _id: "2",
    senderId: {
      username: "John",
      email: "John@ad.com",
    },
  },
];

const MainContainer = styled("div")({
  width: "100%",
  height: "22%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  overflow: "auto",
});

const PendingInvitationsList = () => {
  const { pendingInvitations } = useAppSelector((state) => state.friends);

  return (
    <>
    
      {pendingInvitations.map((invitation) => ( <li >
        <PendingInvitationsListItem
          key={invitation._id}
          id={invitation._id}
          username={invitation.senderId.username}
          email={invitation.senderId.email}
        /></li>
      ))}
      
    </>
  );
};

export default PendingInvitationsList;
