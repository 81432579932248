import React from "react";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import OnlineIndicator from "./OnlineIndicator";
import Avatar from "../../../../components/Avatar";
import { setChosenChatDetails } from "../../../../actions/chatActions";
import { useAppSelector } from "../../../../store";
import AppSimpleBar from "../../../../components/AppSimpleBar";



interface FriendsListItemProps {
    id: string;
    username: string;
    email: string;
    isOnline: boolean;
}

const FriendsListItem = ({
    id,
    username,
    isOnline,
    email,
}: FriendsListItemProps) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const dispatch = useDispatch();

    const { chosenChatDetails } = useAppSelector((state) => state.chat);

    // if this friend is same as the person/user typing and this friend is not the one 
    // we are currently chatting with(i.e, chosenChatDetails.userId)
    const isFriendTyping =
        chosenChatDetails?.typing.typing &&
        chosenChatDetails.userId !== id &&
        id === chosenChatDetails.typing.userId;

    const isChatActive = chosenChatDetails?.userId === id;

    return (
        <Tooltip title={email}>
            <Button
                onClick={() => {
                    dispatch(setChosenChatDetails({ userId: id, username }));
                }}>
               
                        
                            <div className="d-flex align-items-center">

                                {matches && <Avatar username={username} />}

                                <div className="overflow-hidden">
                                    <p className="text-truncate mb-0">  {username}</p>
                                </div>
                            </div>
                    
                
                    
                {isFriendTyping && (
                    <Typography
                        style={{
                            marginLeft: "7px",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "#3ba55d",
                           
                        }}
                        variant="subtitle1"
                        align="left"
                    >
                        typing.....
                    </Typography>
                )}

                {isOnline && <OnlineIndicator />}
            </Button>
        </Tooltip >
    );
};

export default FriendsListItem;
