import react, { useState } from 'react';
import AppSimpleBar from "../../../components/AppSimpleBar";
import { Button, UncontrolledTooltip, Form, Input } from "reactstrap";
import PendingInvitationsList from "./PendingInvitationsList/PendingInvitationsList";
import FriendsList from "./FriendsList/FriendsList";
import { useAppSelector } from "../../../store/index";
import AddFriendDialog from './AddFriendDialog';


const Contacts = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleOpenAddFriendDialog = () => {
        setIsDialogOpen(true);
    };

    const handleCloseAddFriendDialog = () => {
        setIsDialogOpen(false);
    };
    const { friends, onlineUsers } = useAppSelector(state => state.friends);

    const modifiedFriends = friends.map(friend => {
        const isOnline = onlineUsers.find(user => user.userId === friend.id);

        return ({ ...friend, isOnline: !!isOnline });
    })
    return (
        <div className="tab-pan" >
            <div className="position-relative ">
                <div className="px-4 pt-4" >

                    {/* */}
                    <div className="d-flex align-items-start  ">
                        <div className="flex-grow-1">
                            <h4 className="mb-4">Contacts</h4>
                        </div>

                        {/* Button trigger modal */}
                        <div className="flex-shrink-0">
                            <div id="add-contact-button">

                                <button type="button" className="btn btn-soft-primary btn-sm btn btn-primary" onClick={handleOpenAddFriendDialog}>
                                    <i className="bx bx-plus"></i>
                                </button>
                                <AddFriendDialog
                                    isDialogOpen={isDialogOpen}
                                    closeDialogHandler={handleCloseAddFriendDialog}
                                />
                            </div>
                        </div>

                        {/* Button trigger modal */}
                    </div>
                    {/* */}



                    {/*Search Box*/}
                    <Form>
                        <div className="input-group mb-4">
                            <Input
                                type="text"
                                className="form-control bg-light border-0 pe-0"
                                placeholder="Search Contacts.."
                                aria-label="Search Contacts..."
                                aria-describedby="button-searchcontactsaddon"
                            //value={search || ""}
                            //onChange={(e: any) => onChangeSearch(e.target.value)}
                            />
                            <button
                                className="btn btn-light"
                                type="button"
                                id="button-searchcontactsaddon"
                            >
                                <i className="bx bx-search align-middle"></i>
                            </button>
                        </div>
                    </Form>
                    {/*Search Box*/}



                    <div className="position-relative" >
                        <AppSimpleBar className="chat-message-list chat-group-list">
                            <div className="chat-message-list" >
                                <ul className="list-unstyled chat-list chat-user-list">
                                    <FriendsList />
                                </ul>
                            </div>

                        </AppSimpleBar>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Contacts;