import React from "react";
import { styled } from "@mui/system";
import gif from "./dots.gif";
import { useAppSelector } from "../../../store";
import Avatar from "../../../components/Avatar";

const GifContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    
    width: "82px",
    marginLeft: "10px",
});

/* const Typing = () => {
    const {chosenChatDetails} = useAppSelector(state => state.chat);
    
    return (
        <GifContainer>
            {(chosenChatDetails?.typing.typing && chosenChatDetails.userId === chosenChatDetails.typing.userId) && (
                <>
                    <Avatar username={chosenChatDetails?.username!} />
                    <img
                        src={gif}
                        alt="dots"
                        style={{
                          
                            width: "50%",
                            marginLeft: "3px",
                        }}
                    />
                </>
            )}
        </GifContainer>
    );
};
 */

    
const Typing = () => {
    const {chosenChatDetails} = useAppSelector(state => state.chat);
    return (
        <>
          {(chosenChatDetails?.typing.typing && chosenChatDetails.userId === chosenChatDetails.typing.userId) && (
      <p className="mb-0">
        typing
        <span className="animate-typing">
          <span className="dot mx-1"></span>
          <span className="dot me-1"></span>
          <span className="dot"></span>
        </span>
      </p>)} </>
    );
  };
export default Typing;
