import React, { useState } from "react";
import { styled } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import ResizeRoomButton from "./ResizeRoomButton";
import VideosContainer from "./VideosContainer";
import RoomButtons from "./RoomButtons";
import { Button, Modal, ModalBody } from "reactstrap";
import { useAppSelector } from "../../store";
import { CallItem } from "../../data/calls";
import { call } from "redux-saga/effects";


const MainContainer = styled("div")({
position:"absolute",
  zIndex:"100",
  width:"100%",
  height:"100vh",
  marginLeft: "auto",
  marginRight: "auto",
backgroundColor:"rgba(0,0,0,.3)"
});

const con=styled("div")({
   
});

const fullScreenRoomStyle = {
    width: "100%",
    height: "100vh",
    zIndex: 200,
};

const minimizedRoomStyle = {
  marginLeft: "auto",
  marginRight: "auto",
    width: "40%",
    height: "60vh",
   /*  backgroundColor:"transparent" */
};

 interface VideoChatProps {
    call: CallItem;
  } 
const VideoChat:React.FC<{
user:string;
}> = ({ user })  => {
    const [isRoomMinimized, setIsRoomMinimized] = useState(false);

    const roomResizeHandler = () => {
        setIsRoomMinimized(!isRoomMinimized);
    };

    const matches = useMediaQuery("(max-width:800px)");

   
   // const [user, setUser] = useState<null | CallItem>(null);
   /*  const [isOpenVideoModal, setIsOpenVideoModal] = useState<boolean>(false);
    const onOpenVideo = () => {
     // setUser();
      setIsOpenVideoModal(true);
    };
    const onCloseVideo = () => {
      setUser(null);
      setIsOpenVideoModal(false);
    }; */
    return (
        <MainContainer
        className=""
             style={
                isRoomMinimized
                    ? { ...minimizedRoomStyle, ...(matches && {width: "70%"}) }
                    : fullScreenRoomStyle
            }
            sx={{
                ...(matches &&
                    isRoomMinimized && {
                        flexDirection: "row",
                    }),
            }} 
     >
      
         <VideosContainer isRoomMinimized={isRoomMinimized} /> 
            <RoomButtons user={user} isRoomMinimized={isRoomMinimized} />
            <ResizeRoomButton
                isRoomMinimized={isRoomMinimized}
                handleRoomResize={roomResizeHandler}
            />

        </MainContainer>
    );
};

export default VideoChat;
