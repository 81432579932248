import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import SideBar from "./SideBar/SideBar";
import FriendsSideBar from "./FriendsSideBar/FriendsSideBar";
import Messenger from "./Messenger/Messenger";
import AppBar from "./AppBar/AppBar";
import { connectWithSocketServer, UserDetails } from "../../socket/socketConnection";
import { useAppSelector } from "../../store";
import VideoChat from "../../components/VideoChat";
import IncomingCall from "../../components/IncomingCall";
import WelcomeMessage from "./Messenger/WelcomeMessage";
import DefaultLayout from "../../layouts/Default/index";



const Dashboard = () => {
    const { auth: { userDetails }, videoChat: { localStream } } = useAppSelector((state) => state);
    const navigate = useNavigate();
    
   // const { chosenChatDetails } = useAppSelector((state) => state.chat);
    useEffect(() => {
        const isLoggedIn = "token" in userDetails;

        if (!isLoggedIn) {
            navigate("/login");
        } else {
            // connect to socket server
            connectWithSocketServer(userDetails as UserDetails);
            //setUser(userDetails.username)
        }

    }, [userDetails, navigate]);
    const [user, setUser] = useState("username");

    return (

        <>
            <DefaultLayout>
            <FriendsSideBar />
            <Messenger />

            {localStream && <VideoChat user={user} />}
            <IncomingCall />
            </DefaultLayout>

        </>
    );
};

export default Dashboard;
