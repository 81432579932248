import React, { useState, useEffect } from "react";
import { Alert, Form } from "reactstrap";
import Messages from "./Messages";
import { useAppSelector } from "../../../store";
import { notifyTyping, sendDirectMessage } from "../../../socket/socketConnection";
import Typing from "./Typing";

// components
import StartButtons from "./StartButtons";
import NewMessageInput from "./NewMessageInput";
import EndButtons from "./EndButtons";
import MoreMenu from "./MoreMenu";
import Reply from "./Reply";
import MessagesHeader from "./Messages/Header";




const ChatDetails = () => {


  /*more menu collapse*/

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  /*
  disable send button
  */
  const [disabled, setDisabled] = useState<boolean>(true);
  const [images, setImages] = useState<Array<any> | null | undefined>();
  const onSelectImages = (images: Array<any>) => {
    setImages(images);
  };

  /*
  files
  */
  const [files, setFiles] = useState<Array<any> | null | undefined>();
  const onSelectFiles = (files: Array<any>) => {
    setFiles(files);
  };

  const [text, setText] = useState<null | string>(null);
  const onChangeText = (value: string) => {
    setText(value);
  };

  useEffect(() => {
    if (text || images || files) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [text, images, files]);

  const [message, setMessage] = useState("");
  const [focused, setFocused] = useState(false);

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const chatDetails = useAppSelector((state) => state.chat.chosenChatDetails);

  /*  const handleSendMessage = (e: React.KeyboardEvent<HTMLInputElement>) => {
 
     if (e.key === "Enter") {
       sendDirectMessage({
         message,
         receiverUserId: chatDetails?.userId!,
       });
 
       setMessage("");
     }
   }; */

  const handleClick = () => {
    sendDirectMessage({
      message,
      receiverUserId: chatDetails?.userId!,
    });
    setMessage("");
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value)
  };


  useEffect(() => {

    // notify the receiverUser that the user(sender) is typing
    notifyTyping({ receiverUserId: chatDetails?.userId!, typing: focused });

  }, [focused]);

  const onSubmit = () => {

    console.log("hello", message);
    if(message) {
      debugger;
        sendDirectMessage({
            message,
            receiverUserId: chatDetails?.userId!,
            
        });
  
        setMessage("");
    }
  /*   let data: any = {};
 if (text) {
      data["text"] = text;
    } 

 


    if (images && images.length) {
      const imgs = (images || []).map((i: any, key: number) => {
        const src = URL.createObjectURL(i);
        return {
          id: key + 1,
          downloadLink: src,
        };
      });
      data["image"] = imgs;
    }

    if (files && files.length) {
      const fs = (files || []).map((f: any, key: number) => {
        const src = URL.createObjectURL(f);
        return {
          id: key + 1,
          name: f.name,
          downloadLink: src,
          desc: f.size,
        };
      });
      data["attachments"] = fs;
    } */
  }

  return (
    <div className="">
      <div className="chat-content d-lg-flex">
        <div className="w-100 overflow-hidden position-relative">
          <MessagesHeader scrollPosition={0}  />
          <Messages />
           <Typing /> 
         <NewMessageInput />
        </div>
      </div> 



    </div>
  );
};

export default ChatDetails;
