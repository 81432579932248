import { createStore, combineReducers, applyMiddleware,compose } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { authReducer } from "../reducers/authReducer";
import { alertReducer } from "../reducers/alertReducer";
import { friendsReducer } from "../reducers/friendsReducer";
import { chatReducer } from "../reducers/chatReducer";
import videoChatReducer from "../reducers/videoChatReducer";
import reducers from "./redux/reducers";
import rootSaga from "./redux/sagas";
import createSagaMiddleware from "redux-saga";
import { AppDispatch } from "./store";
import Chats from "./redux/chats/reducer";
import Layout from "./layout/reducer";
import Contacts from "./redux/contacts/reducer";




const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}


const rootReducer = combineReducers({
    auth: authReducer,
    alert: alertReducer,
    friends: friendsReducer,
    chat: chatReducer,
    videoChat: videoChatReducer,
    layout:Layout,
    
});

const store = createStore(
  rootReducer,
  
  composeWithDevTools(applyMiddleware(thunk))


); 

/* const store;
export function configureStore(initialState: any) {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
  sagaMiddleware.run(rootSaga);
  //return store;
}
 */
// Infer the `RootState` and `AppDispatch` types from the store itself
//type RootState2 = ReturnType<typeof store.getState>;
export type RootState = ReturnType<typeof rootReducer>;
export type RootState2 = ReturnType<typeof store.getState>;
//type AppDispatch = typeof store.dispatch;

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppSelector2: TypedUseSelectorHook<RootState2> = useSelector;//template

export { store, useAppSelector, useAppSelector2 };



