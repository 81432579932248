import React, { useState, useEffect, useRef , useCallback}  from "react";
import { useAppSelector } from "../../../../store";
import { fetchDirectChatHistory } from "../../../../socket/socketConnection";
import { Message as MessageType } from "../../../../actions/types";
import DateSeparator from "./DateSeparator";
import AppSimpleBar from "../../../../components/AppSimpleBar";
import Message from "../Messages/Message";
import { notifyTyping, sendDirectMessage } from "../../../../socket/socketConnection";


const Messages = () => {
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const {chat, auth: {userDetails}} = useAppSelector((state) => state);

    const { chosenChatDetails: chatDetails, messages } = chat;

    const sameAuthor = (message: MessageType, index: number) => {

        if (index === 0) {
            return false;
        }
        return message.author._id === messages[index - 1].author._id;
    }

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef?.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        setScrollPosition(e.currentTarget.scrollTop);
    };
    
    useEffect(() => {

        if (chatDetails) {
            fetchDirectChatHistory({receiverUserId: chatDetails.userId});
        }

    }, [chatDetails]);


    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const ref = useRef<any>();
    const scrollElement = useCallback(() => {
      if (ref && ref.current) {
        const listEle = document.getElementById("chat-conversation-list");
        let offsetHeight = 0;
        if (listEle) {
          offsetHeight = listEle.scrollHeight - window.innerHeight + 250;
        }
        if (offsetHeight) {
          ref.current
            .getScrollElement()
            .scrollTo({ top: offsetHeight, behavior: "smooth" });
        }
      }
    }, [ref]);


    const [isOpen, setIsOpen] = useState<boolean>(false);
    const onToggle = () => {
      setIsOpen(!isOpen);
    };
  
    /*
    disable send button
    */
    const [disabled, setDisabled] = useState<boolean>(true);
    const [images, setImages] = useState<Array<any> | null | undefined>();
    const onSelectImages = (images: Array<any>) => {
      setImages(images);
    };
  
    /*
    files
    */
    const [files, setFiles] = useState<Array<any> | null | undefined>();
    const onSelectFiles = (files: Array<any>) => {
      setFiles(files);
    };
  
    const [text, setText] = useState<null | string>(null);
    const onChangeText = (value: string) => {
      setText(value);
    };
  
    useEffect(() => {
      if (text || images || files) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }, [text, images, files]);
  
    const [message, setMessage] = useState("");
    const [focused, setFocused] = useState(false);
  
    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);
  
 
  
   const handleClick = () => {
      sendDirectMessage({
        message,
        receiverUserId: chatDetails?.userId!,
      });
      setMessage("");
    }
  
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setMessage(e.target.value)
    };
  
  
    useEffect(() => {
  
      // notify the receiverUser that the user(sender) is typing
      notifyTyping({ receiverUserId: chatDetails?.userId!, typing: focused });
  
    }, [focused]);
  
   
 return (
        <AppSimpleBar
        scrollRef={ref}
        className="chat-conversation p-3 p-lg-4 positin-relative"
      >
        {/* {getUserConversationsLoading && <Loader />} */}
        <ul
          className="list-unstyled chat-conversation-list  "
          id="chat-conversation-list"
        >
            {messages.map((message, index) => {
                const thisMessageDate = new Date(
                    message.createdAt
                ).toDateString();
                const prevMessageDate =
                    index > 0 &&
                    new Date(messages[index - 1]?.createdAt).toDateString();

                const isSameDay =
                    index > 0 ? thisMessageDate === prevMessageDate : true;

                const incomingMessage =
                    message.author._id === chatDetails?.userId;

                return (
                    <div key={message._id} style={{ width: "97%" }}>
                        {(!isSameDay || index === 0) && (
                            <DateSeparator date={message.createdAt} />
                        )}

                        <Message
                            content={message.content}
                            username={message.author.username}
                            sameAuthor={sameAuthor(message, index)}
                            date={message.createdAt}
                            incomingMessage={incomingMessage}
                            isChannel={incomingMessage}
                        />
                    </div>
                );
            })}
           </ul>
     </AppSimpleBar>
    );
};

export default Messages;
