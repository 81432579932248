import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import { Button } from "reactstrap";

const Camera: React.FC<{
    localStream: MediaStream;
}> = ({ localStream }) => {
    const [cameraEnabled, setCameraEnabled] = useState(true);

    const handleToggleCamera = () => {
        localStream.getVideoTracks().forEach((track) => track.enabled = !track.enabled);
        setCameraEnabled(!cameraEnabled);
    };

    return (
        <div className="avatar-md h-auto">
            <Button
                color="light"
                type="button"
                className="avatar-sm rounded-circle"
                onClick={handleToggleCamera}
            >

                <span className="avatar-title bg-transparent text-muted font-size-20">
                    {cameraEnabled ? <VideocamIcon /> : <VideocamOffIcon />}
                </span>
            </Button>
        </div>
    );
};

export default Camera;
