import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import { validateMail } from "../../../utils/validators";

import { inviteFriend } from "../../../actions/friendActions";
import {
    Form,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Input,
} from "reactstrap";

import 'bootstrap/dist/css/bootstrap.min.css';





interface AddFriendDialogProps {
    isDialogOpen: boolean;
    closeDialogHandler: () => void;
}

const AddFriendDialog = ({
    isDialogOpen,
    closeDialogHandler,
}: AddFriendDialogProps) => {
    const [email, setEmail] = useState("");
    const [isFormValid, setIsFormValid] = useState(false);

    const dispatch = useDispatch();

    const handleCloseDialog = () => {
        closeDialogHandler();
        setEmail("");
    };

    const handleClick = () => {
        dispatch(inviteFriend(email, handleCloseDialog));
    }

    useEffect(() => {
        setIsFormValid(validateMail(email));
    }, [email, setIsFormValid]);

    return (
        <div className="" style={{ position: 'relative', display: 'block', width: '100%' }}>
            <div className="modal " >
                <div className="modal fade show">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document" style={{ width: '100%' }}>
                        <div className="modal-content">
                            <Modal isOpen={isDialogOpen} toggle={handleCloseDialog}>
                                <ModalHeader  className="modal-title-custom" toggle={handleCloseDialog}>
                                   Add Contact
                                </ModalHeader>
                                <ModalBody className="p-4">
        <Form>
          <div className="mb-3">
            <Label htmlFor="AddContactModalemail-input" className="form-label">
              Email
            </Label>
            <Input
              type="email"
              className="form-control"
              id="AddContactModalemail-input"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <Label htmlFor="AddContactModalname-input" className="form-label">
              Name
            </Label>
            <Input
              type="text"
              className="form-control"
              id="AddContactModalname-input"
              placeholder="Enter Name"
             /*  value={data["name"] || ""}
              onChange={(e: any) => {
                onChangeData("name", e.target.value);
              }} */
            />
          </div>
          <div className="mb-3">
            <Label
              htmlFor="AddContactModal-invitemessage-input"
              className="form-label"
            >
              Invatation Message
            </Label>
            <textarea
             /*  value={data["message"] || ""}
              onChange={(e: any) => {
                onChangeData("message", e.target.value);
              }} */
              className="form-control"
              id="AddContactModal-invitemessage-input"
              rows={3}
              placeholder="Enter Message"
            ></textarea>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="link" className="btn" onClick={handleCloseDialog}>
          Close
        </Button>
        <Button
          type="button"
          color="primary"
          disabled={!isFormValid}
          onClick={handleClick}
        >
          Invite
        </Button>
      </ModalFooter>
    </Modal>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    );
};

export default AddFriendDialog;
