import React, { useState } from "react";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";


interface MenuProps {
    onDelete: () => any;
    onReply: () => any;
    onForward: (data: any) =>any;
  }
  
  const Menu = ({ onDelete, onReply, onForward }: MenuProps) => {
    return (
      <UncontrolledDropdown className="align-self-start message-box-drop">
        <DropdownToggle className="btn btn-toggle" role="button" tag={"a"}>
          <i className="ri-more-2-fill"></i>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            className="d-flex align-items-center justify-content-between"
            to="#"
            onClick={onReply}
          >
            Reply <i className="bx bx-share ms-2 text-muted"></i>
          </DropdownItem>
          <DropdownItem
            className="d-flex align-items-center justify-content-between"
            to="#"
            onClick={onForward}
          >
            Forward <i className="bx bx-share-alt ms-2 text-muted"></i>
          </DropdownItem>
          <DropdownItem
            className="d-flex align-items-center justify-content-between"
            to="#"
          >
            Copy <i className="bx bx-copy text-muted ms-2"></i>
          </DropdownItem>
          <DropdownItem
            className="d-flex align-items-center justify-content-between"
            to="#"
          >
            Bookmark <i className="bx bx-bookmarks text-muted ms-2"></i>
          </DropdownItem>
          <DropdownItem
            className="d-flex align-items-center justify-content-between"
            to="#"
          >
            Mark as Unread <i className="bx bx-message-error text-muted ms-2"></i>
          </DropdownItem>
          <DropdownItem
            className="d-flex align-items-center justify-content-between delete-item"
            onClick={onDelete}
          >
            Delete <i className="bx bx-trash text-muted ms-2"></i>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  export default Menu;