import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate} from "react-router-dom";
import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import { Tooltip } from "@mui/material";
//import Button from "@mui/material/Button";
import AuthBox from "../components/AuthBox";
import { validateRegisterForm } from "../utils/validators";
import { useAppSelector } from "../store";
import { registerUser } from "../actions/authActions";
import { Alert, Row, Col, Form, Button, UncontrolledTooltip } from "reactstrap";
// router
import { Link } from "react-router-dom";

/* // validations
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

// hooks
//import { useProfile } from "../../hooks";

//actions
//import { registerUser } from "../../redux/actions"; */

// components
import NonAuthLayoutWrapper from "../components/NonAutnLayoutWrapper";
import AuthHeader from "../components/AuthHeader";
//import FormInput from "../components/FormInput";
/* const Wrapper = styled("div")({
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
});

const Label = styled("p")({
    color: "#b9bbbe",
    textTransform: "uppercase",
    fontWeight: "600",
    fontSize: "16px",
});

const Input = styled("input")({
    flexGrow: 1,
    height: "40px",
    border: "1px solid black",
    borderRadius: "5px",
    color: "#dcddde",
    background: "#35393f",
    margin: 0,
    fontSize: "16px",
    padding: "0 5px",
    outline: "none",
});

const RedirectText = styled("span")({
    color: "#00AFF4",
    fontWeight: 500,
    cursor: "pointer",
}); */

const Register = () => {
    const navigate = useNavigate();
    const [credentials, setCredentials] = useState({
        email: "",
        password: "",
        username: ""
    });
    const [isFormValid, setIsFormValid] = useState(false);

    const { error, errorMessage, userDetails } = useAppSelector(
        (state) => state.auth
    );

    const dispatch = useDispatch();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value,
        });
    };

    const onSubmitForm = () => {
        dispatch(registerUser(credentials));
    };

    useEffect(() => {
        setIsFormValid(validateRegisterForm(credentials));
    }, [credentials]);

   

    useEffect(() => {
        if ("token" in userDetails) {
            navigate("/login");
        }
    }, [userDetails, navigate]);


   /*  const resolver = yupResolver(
        yup.object().shape({
          email: yup
            .string()
            .email("This value should be a valid email.")
            .required("Please Enter E-mail."),
          username: yup.string().required("Please Enter E-mail."),
          password: yup.string().required("Please Enter Password."),
        })
      ); */
    /* 
      const defaultValues: any = {};

    const methods = useForm({ defaultValues, resolver });
    const {
      handleSubmit,
      register,
      control,
      formState: { errors },
    } = methods;
 */
    return (
       
        <NonAuthLayoutWrapper>
        <Row className=" justify-content-center my-auto">
          <Col sm={8} lg={6} xl={5} className="col-xxl-4">
            <div className="py-md-5 py-4">
              <AuthHeader
                title="Register Account"
                subtitle="Get your free Doot account now."
              />
  
              {/* {user && user ? (
                <Alert color="success">Register User Successfully</Alert>
              ) : null} */}
  
             {/*  {registrationError && registrationError ? (
                <Alert color="danger">{registrationError}</Alert>
              ) : null} */}
    <Form
               
            /*  onSubmit={handleSubmit(onSubmitForm)} */
                className="position-relative"
              > 
               {/*  {regLoading && <Loader />} */}
                <div className="mb-3">
                <label>Email</label>
                  <input
                    //label="Email"
                    type="text"
                    name="email"
                   /*  register={register}
                    errors={errors}
                    control={control} */
                    //labelClassName="form-label"
                    placeholder="Enter Email"
                    className="form-control"
                    value={credentials.email}
                    onChange={handleChange}
                  />
                </div>
  
                <div className="mb-3">
                <label>Username</label>
                  <input
                    //label="Username"
                    type="text"
                    name="username"
                  /*   register={register}
                    errors={errors}
                    control={control} */
                    //labelClassName="form-label"
                    placeholder="Enter username"
                    className="form-control"
                   value={credentials.username}
                    onChange={handleChange}
                  />
                </div>
  
                <div className="mb-3">
                <label>Password</label>
                  <input
                    //label="Password"
                    type="password"
                    name="password"
                  /*   register={register}
                    errors={errors}
                    control={control} */
                    //labelClassName="form-label"
                    className="form-control pe-5"
                    placeholder="Enter Password"
                    value={credentials.password}
                    onChange={handleChange}
                  />
                </div>
  
                <div className="mb-4">
                  <p className="mb-0">
                    By registering you agree to the Doot{" "}
                    <Link to="#" className="text-primary">
                      Terms of Use
                    </Link>
                  </p>
                </div>
  
              

                
                
            <Tooltip
                title={
                    isFormValid
                        ? "Proceed to Register"
                        : "Enter correct email address. Password should be greater than six characters and username should be between 3 and 12 characters!"
                }
            >
                  <div className="text-center mb-3">
                  <Button
                    color="primary"
                    className="w-100  waves-effect waves-light"
                    disabled={!isFormValid}
                    onClick={onSubmitForm}
                    //type="submit"
                  >
                    Register
                  </Button>
                </div>
            </Tooltip>


                <div className="mt-4 text-center">
                  <div className="signin-other-title">
                    <h5 className="font-size-14 mb-4 title">Sign up using</h5>
                  </div>
                {/*   <Row className="">
                    <div className="col-4">
                      <div>
                        <button
                          type="button"
                          className="btn btn-light w-100"
                          id="facebook"
                        >
                          <i className="mdi mdi-facebook text-indigo"></i>
                        </button>
                      </div>
                      <UncontrolledTooltip placement="top" target="facebook">
                        Facebook
                      </UncontrolledTooltip>
                    </div>
                    <div className="col-4">
                      <div>
                        <button
                          type="button"
                          className="btn btn-light w-100"
                          id="twitter"
                        >
                          <i className="mdi mdi-twitter text-info"></i>
                        </button>
                      </div>
                      <UncontrolledTooltip placement="top" target="twitter">
                        Twitter
                      </UncontrolledTooltip>
                    </div>
                    <div className="col-4">
                      <div>
                        <button
                          type="button"
                          className="btn btn-light w-100"
                          id="google"
                        >
                          <i className="mdi mdi-google text-danger"></i>
                        </button>
                      </div>
                      <UncontrolledTooltip placement="top" target="google">
                        Google
                      </UncontrolledTooltip>
                    </div>
                  </Row> */}
                </div>
              </Form>
  
              <div className="mt-5 text-center text-muted">
                <p>
                  Already have an account ?{" "}
                  <Link
                    to="/login"
                    className="fw-medium text-decoration-underline"
                  >
                    Login
                  </Link>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </NonAuthLayoutWrapper>
      
    );
};

export default Register;
