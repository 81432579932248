import React, { useEffect, useState } from "react";
import { useAppSelector, useAppSelector2 } from "../../../store/index";
import { TABS } from "../../../constants/index";
import { TabContent, TabPane } from "reactstrap";
import Invitations from './PendingInvitationsList/Invitations';
import Contacts from './Contacts';


interface ListHeaderProps {
  openModal: () => void;
  onChangeSearch: (value: string) => void;
  search: string;
}

const FriendsSideBar = () => {
 
  //pending invitations
  const { pendingInvitations } = useAppSelector((state) => state.friends);

  ///////


  /*  const { activeTab } = useAppSelector2(state => ({
     activeTab: state.Layout.activeTab,
   })); */
  //invite Friend
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenAddFriendDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseAddFriendDialog = () => {
    setIsDialogOpen(false);
  };
  const { friends, onlineUsers } = useAppSelector(state => state.friends);

  const modifiedFriends = friends.map(friend => {
    const isOnline = onlineUsers.find(user => user.userId === friend.id);

    return { ...friend, isOnline: !!isOnline };
  })

 
  const { activeTab } = useAppSelector(state => ({
    activeTab: state.layout.activeTab,
  }));

  return (
    <>
      {/* start chat-leftsidebar */}
      <div className="chat-leftsidebar" >
        <TabContent activeTab={activeTab}>
          {/* Start Profile tab-pane */}
          

          <TabPane
            tabId={TABS.CHAT}
            role="tabpanel"
            aria-labelledby="pills-chat-tab"
          >
            <Invitations />
          </TabPane>

          <TabPane
            tabId={TABS.CONTACTS}
            role="tabpanel"
            aria-labelledby="pills-contacts-tab"
          >
            <Contacts />
          </TabPane>

        </TabContent>
      </div>
    
    </>
  );
};

export default FriendsSideBar;
