import React from "react";
import { styled } from "@mui/system";
import Video from "./Video";
import { useAppSelector } from "../../store"
import { Typography } from "@mui/material";

const MainContainer = styled("div")({
    height: "60%",
    width: "40%",
    borderRadius: "8px",
    marginLeft: "auto",
    marginRight: "auto", 
    position:"relative",
    top:"10%"
  
});
const VideosContainer: React.FC<{ isRoomMinimized: boolean; }> = ({ isRoomMinimized }) => {
    const { localStream, callStatus, remoteStream, screenSharingStream } =
        useAppSelector((state) => state.videoChat);


    return (

        <MainContainer>
          {/*  {localStream && (
                <Video
                    stream={
                        screenSharingStream ? screenSharingStream : localStream
                    }
                    isLocalStream={true}
                />
            )}  */}

            {/* {callStatus !== "accepted" && (<Typography>
                {callStatus === "ringing" ? "Ringing...." : callStatus === "rejected" && "Call Rejected"}
            </Typography>
            )} */}


             {remoteStream && (
                <Video stream={remoteStream} isLocalStream={false} />
            )}


        </MainContainer>
    );
};


export default VideosContainer;
