import React, { useState } from "react";
//import IconButton from "@mui/material/IconButton";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import { Button, Modal, ModalBody } from "reactstrap";

const Microphone: React.FC<{
    localStream: MediaStream;
}> = ({ localStream }) => {
    const [micEnabled, setMicEnabled] = useState(true);

    const handleToggleMic = () => {
        localStream.getAudioTracks().forEach((track) => track.enabled = !track.enabled);
        setMicEnabled(!micEnabled);
    };

    return (
        <div className="avatar-md h-auto">
            <Button
                color="light"
                type="button"
                className="avatar-sm rounded-circle"
                onClick={handleToggleMic}
            >

                <span className="avatar-title bg-transparent text-muted font-size-20">
                    {micEnabled ? <MicIcon /> : <MicOffIcon />}
                </span>
            </Button>
        </div>
        /*   <IconButton onClick={handleToggleMic} style={{ color: "white" }}>
            </Ic  onButton> */
    );
};

export default Microphone;
