import React, { useState, useEffect } from "react";
import { notifyTyping, sendDirectMessage } from "../../../socket/socketConnection";
import { Button, UncontrolledPopover, PopoverBody } from "reactstrap";
import Messages from "./Messages";
import { useAppSelector } from "../../../store";
interface EndButtonsProps {
 // onClick: React.MouseEventHandler<HTMLButtonElement>;
// onClick: (event: React.MouseEvent<HTMLElement>) => void;
  disabled: boolean;
  onClick:()=>void;
}

const EndButtons = ({onClick,  disabled }: EndButtonsProps) => {
  const [message, setMessage] = useState("");
  const chatDetails = useAppSelector((state) => state.chat.chosenChatDetails);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    sendDirectMessage({
      message,
      receiverUserId: chatDetails?.userId!,
    });
    setMessage("");
  }
  return (
    <div className="chat-input-links ms-2 gap-md-1">
      <div className="links-list-item d-none d-sm-block">
        <Button
          color="none"
          type="button"
          className="btn btn-link text-decoration-none btn-lg waves-effect"
          id="audio-btn"
        >
          <i className="bx bx-microphone align-middle"></i>
        </Button>
      </div>
      <UncontrolledPopover trigger="focus" placement="top" target="audio-btn">
        <PopoverBody>
          <div className="loader-line">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </PopoverBody>
      </UncontrolledPopover>
      <div className="links-list-item">
        <Button
          color="primary"
          //type="submit"
          //disabled={disabled}
          className="btn btn-primary btn-lg chat-send waves-effect waves-light"
          onClick={onClick}
        >
          <i className="bx bxs-send align-middle"></i>
        </Button>
      </div>
    </div>
  );
};

export default EndButtons;
