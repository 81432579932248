import React, {useEffect} from 'react';
import {useDispatch , useSelector} from "react-redux";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { autoLogin } from './actions/authActions';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Register from './pages/Register';
import AlertNotification from "./components/AlertNotification";
import "./assets/scss/theme.scss";
import { changelayoutMode } from "./store/redux/actions";

function App() {

    const dispatch = useDispatch();


  const { layoutMode } = useSelector((state: any) => ({
    layoutMode: state.layout.layoutMode,
  }));

  // Dark/Light Mode 
  useEffect(() => {
    var getLayoutMode = localStorage.getItem("layoutMode");
    if (getLayoutMode) {
      dispatch(changelayoutMode(getLayoutMode));
    } else {
      dispatch(changelayoutMode(layoutMode));
    }
  }, [layoutMode, dispatch]);


  // auto login
  useEffect(() => {
    dispatch(autoLogin());
  }, [dispatch])
  
  return (
      <>
          <BrowserRouter>
              <Routes>
                  <Route path="/register" element={<Register />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="*" element={<Navigate to="/dashboard" />} />
              </Routes>
          </BrowserRouter>
          <AlertNotification />
      </>
  );
}

export default App;
