import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import { useAppSelector } from "../../../store";
import { notifyTyping, sendDirectMessage } from "../../../socket/socketConnection";

import { Alert, Form } from "reactstrap";
import { Input } from "reactstrap";


// components
import StartButtons from "./StartButtons";

import EndButtons from "./EndButtons";
import MoreMenu from "./MoreMenu";
import Reply from "./Reply";
import MessagesHeader from "./Messages/Header";

// components
/* import StartButtons from "./StartButtons";
import InputSection from "./InputSection";
import EndButtons from "./EndButtons";
import MoreMenu from "./MoreMenu";
import Reply from "./Reply"; */


interface NewMessageInputProps {
  //value: null | string;
  //onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const NewMessageInput = () => {

  /*more menu collapse*/

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  /*
  disable send button
  */
  const [disabled, setDisabled] = useState<boolean>(true);
  const [images, setImages] = useState<Array<any> | null | undefined>();
  const onSelectImages = (images: Array<any>) => {
    setImages(images);
  };

  /*
  files
  */
  const [files, setFiles] = useState<Array<any> | null | undefined>();
  const onSelectFiles = (files: Array<any>) => {
    setFiles(files);
  };

  const [text, setText] = useState<null | string>(null);
  const onChangeText = (value: string) => {
    setText(value);
  };

  useEffect(() => {
    if (text || images || files) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [text, images, files]);




  const [message, setMessage] = useState("");
  const [focused, setFocused] = useState(false);

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const chatDetails = useAppSelector((state) => state.chat.chosenChatDetails);

  const handleSendMessage = (e: React.KeyboardEvent<HTMLInputElement>) => {

    if (e.key === "Enter") {
      sendDirectMessage({
        message,
        receiverUserId: chatDetails?.userId!,
      });

      setMessage("");
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value)
  };

  const onSubmit = () => {

    console.log("hello", message);
    if (message) {
      //debugger;
      sendDirectMessage({
        message,
        receiverUserId: chatDetails?.userId!,

      });

     
    } setMessage("");
  };

    useEffect(() => {

      // notify the receiverUser that the user(sender) is typing
      notifyTyping({ receiverUserId: chatDetails?.userId!, typing: focused });

    }, [focused]);

    return (

      <>
        <div className="chat-input-section p-3 p-lg-4">
          <Form
            id="chatinput-form"
            onSubmit={(e: any) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <div className="row g-0 align-items-center">
              <div className="col-auto">
                <StartButtons
                  onToggle={onToggle}
                  text={text}
                  onChange={onChangeText}
                />
              </div>
              
              <div className="col">
                <div className="position-relative ">
                  <Input
                    className="form-control form-control-lg chat-input "
                    id="chat-input"
                    placeholder="Type your message..."

                    //placeholder={`Write message to ${chatDetails?.username}`}
                    value={message}
                    onChange={handleChange}
                    onKeyDown={handleSendMessage}
                    onFocus={onFocus}
                    onBlur={onBlur}

                  />
                </div>
              </div>
              <div className="col-auto">
                <EndButtons onClick={onSubmit} disabled={disabled} />
              </div>
            </div>
          </Form>

          {(images && images.length) || (files && files.length) ? (
            <Alert
              isOpen={true}
              /* toggle={onClearMedia} */
              color="secondary"
              className="alert-dismiss-custom 
        rounded-pill font-size-12 mb-1 selected-media"
              closeClassName="selected-media-close"
            >
              <p className="me-2 mb-0">
                {images && !files && ` You have selected ${images.length} images`}
                {files && !images && ` You have selected ${files.length} files`}
                {files &&
                  images &&
                  ` You have selected ${files.length} files & ${images.length} images.`}
              </p>
            </Alert>
          ) : null}

          <MoreMenu
            isOpen={isOpen}
            onSelectImages={onSelectImages}
            onSelectFiles={onSelectFiles}
            onToggle={onToggle}
          />
        </div>
      </>

    );
  };

  export default NewMessageInput;
