import React, {useState, useEffect} from 'react'
import {useNavigate} from "react-router-dom";
import {useDispatch} from 'react-redux';

//import AuthBox from '../components/AuthBox'
import {validateLoginForm} from "../utils/validators"
import {loginUser} from "../actions/authActions";
import { useAppSelector } from '../store';
import {
  Alert,
  Row,
  Col,
  Form,
  Label,
  Button,
  UncontrolledTooltip,
} from "reactstrap";

// router
import { Link,useLocation } from "react-router-dom";



// components
import NonAuthLayoutWrapper from "../components/NonAutnLayoutWrapper";
import AuthHeader from "../components/AuthHeader";


interface LocationTypes {
    from?: Location;
  }
  interface LoginProps {}

  const Login = () => {
    
  const navigate = useNavigate();
  const dispatch = useDispatch ();
  const [credentials, setCredentials] = useState({
      email: "",
      password: ""
  });
  const [isFormValid, setIsFormValid] = useState(false); 

  const {error, errorMessage, userDetails} = useAppSelector(state => state.auth) 


  const handleChange = (e: React.ChangeEvent<HTMLInputElement> ) => {
      setCredentials({
          ...credentials,
          [e.target.name]: e.target.value
      })
  }

  const handleLogin = () => {
      dispatch(loginUser(credentials))
  }


  useEffect(() => {
      setIsFormValid(validateLoginForm(credentials))
  }, [credentials])


  useEffect(() => {
      
      if ("token" in userDetails) {
        navigate("/dashboard")
      }

    }, [userDetails, navigate])
  
    return (
      <NonAuthLayoutWrapper>
        <Row className=" justify-content-center my-auto">
          <Col sm={8} lg={6} xl={5} className="col-xxl-4">
            <div className="py-md-5 py-3">
              <AuthHeader
                title="Welcome Back !"
                subtitle="Sign in to continue to Doot."
              />

              <div
                //onSubmit={handleSubmit(onSubmitForm)}
                className="position-relative"
              >
           
                <div className="mb-3">
                    <label>Email</label>
                  <input
                  
                    type="text"
                    name="email"
                   
                    placeholder="Enter Email"
                    className="form-control"
                     value={credentials.email}
                   onChange={handleChange} 
                  />
                </div>
  
                <div className="mb-3">
                    <label>Password</label>
                  <input
                    
                    type="password"
                    name="password"
                    
                    className="form-control pe-5"
                    placeholder="Enter Password"
                    value={credentials.password}
                    onChange={handleChange} 
                  />
                </div>
  
                <div className="form-check form-check-info font-size-16">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="remember-check"
                  />
                  <Label
                    className="form-check-label font-size-14"
                    htmlFor="remember-check"
                  >
                    Remember me
                  </Label>
                </div>
  
                <div className="text-center mt-4">
                  <Button color="primary" className="w-100" type="submit"  disabled={!isFormValid}
                      onClick={handleLogin}>
                    Log In
                  </Button>
                </div>
              </div>
  
              <div className="mt-5 text-center text-muted">
                <p>
                  Don't have an account ?{" "}
                  <Link
                    to="/register"
                    className="fw-medium text-decoration-underline"
                  >
                    {" "}
                    Register
                  </Link>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </NonAuthLayoutWrapper>
    );
  };
  
  export default Login;
  