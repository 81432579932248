import React, { useEffect, useState, useRef, useCallback } from "react";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import Menu from "./Menu";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Avatar from "../../../../components/Avatar";

import {
  toggleUserDetailsTab,
  getChatUserConversations,
  onSendMessage,
  receiveMessage,
  readMessage,
  receiveMessageFromUser,
  deleteMessage,
  deleteUserMessages,
  toggleArchiveContact,
} from "../../../../store/redux/actions";

import { useRedux } from "../../../../hooks/useRedux";
//import { useProfile } from "../../../../hooks";

// interface
import { MessagesTypes } from "../../../../data/messages";
import Typing from '../Typing';



import AppSimpleBar from "../../../../components/AppSimpleBar";
import Loader from "../../../../components/Loader";

import ForwardModal from "../../../../components/ForwardModal";

// actions
import { forwardMessage, deleteImage } from "../../../../store/redux/actions";

interface MessageProps {
  content: string;
  sameAuthor: boolean;
  username: string;
  date: string;
  incomingMessage: boolean;
  isChannel: boolean;
  /* onDelete: (messageId: string | number) => any;
  onSetReplyData: (reply: null | MessagesTypes | undefined) => void; */
}




const Message = ({ content, sameAuthor, username, date, incomingMessage, isChannel }: MessageProps) => {

  /////
  const { dispatch, useAppSelector } = useRedux();


  const onOpenUserDetails = () => {
    dispatch(toggleUserDetailsTab(true));
  };



  /*
  send message
  */
  const onSend = (data: any) => {
    let params: any = {
      text: data.text && data.text,
      time: new Date().toISOString(),
      image: data.image && data.image,
      attachments: data.attachments && data.attachments,
      meta: {
        //receiver: username,
        //sender: userProfile.uid,
      },
    };
  }
  /*  if (replyData && replyData !== null) {
     params["replyOf"] = replyData;
   } 

   dispatch(onSendMessage(params));
   
   setReplyData(null);
 };
*/





  const { chosenChatDetails } = useAppSelector((state) => state.chat);
  const ref = useRef<any>();
  const scrollElement = useCallback(() => {
    if (ref && ref.current) {
      const listEle = document.getElementById("chat-conversation-list");
      let offsetHeight = 0;
      if (listEle) {
        offsetHeight = listEle.scrollHeight - window.innerHeight + 250;
      }
      if (offsetHeight) {
        ref.current
          .getScrollElement()
          .scrollTo({ top: offsetHeight, behavior: "smooth" });
      }
    }
  }, [ref]);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.recalculate();
    }
  }, []);
  useEffect(() => {
    if (content) {
      scrollElement();
    }
  }, [content, scrollElement]);

  /*
  forward message
  */
  const [forwardData, setForwardData] = useState<null | MessagesTypes | undefined>();
  const [isOpenForward, setIsOpenForward] = useState<boolean>(false);
  const onOpenForward = (message: MessagesTypes) => {
    setForwardData(message);
    setIsOpenForward(true);
  };
  const onCloseForward = () => {
    setIsOpenForward(false);
  };
  const onDeleteMessage = () => {
    console.log("delete")
  };

  const onClickReply = () => {
    console.log("reply");
  };

  const onForwardMessage = (data: any) => {
    const params = {
      contacts: data.contacts,
      message: data.message,
      forwardedMessage: forwardData,
    };
    dispatch(forwardMessage(params));
  };

  /*  const hasImages = message.image && message.image.length;
   const hasAttachments = message.attachments && message.attachments.length;
   const hasText = message.text;
   const isTyping = false;
   const profile = isChannel ? channeluserProfile : chatUserprofile;
   const date = formateDate(message.time, "hh:mmaaa");
   const isSent = message.meta.sent;
   const isReceived = message.meta.received;
   const isRead = message.meta.read;
   const isForwarded = message.meta.isForwarded;
   const channdelSenderFullname = message.meta.userData
    */
  if (!incomingMessage) {
    return (
      <>
        <li
          className={classnames(
            "chat-list right  d-flex justify-content-end mt-4",
            /*  { right: isFromMe },
             { reply: isRepliedMessage } */
          )}
        >
          <div className="conversation-list">
            <div className="chat-avatar ">
              <Avatar username={username} />
            </div>

            <div className="user-chat-content">
              <div className="ctext-wrap">
                <div className="ctext-wrap-content">
                  <p className="mb-0 ctext-content">{content}</p>
                </div>

                <Menu
                  onForward={onForwardMessage}
                  onDelete={onDeleteMessage}
                  onReply={onClickReply}
                />

              </div>

              <div className="conversation-name">
                <small className={classnames("text-muted", "mb-0", "me-2")}>
                  {new Date(date).toLocaleTimeString()}
                </small>
              </div>


            </div>
          </div>
        </li>
      </>
    );
  }



  //arrived message 
  return (
    <>
      <li
        className={classnames(
          "  ",

        )}
      >
        <div className="conversation-list">
          <div className="chat-avatar">
            <Avatar username={username} />
          </div>

          <div className="user-chat-content">
            <div className="ctext-wrap">
              <div className="ctext-wrap-content">
                <div className=" mb-2 d-flex align-items-start">
                  <div className="flex-grow-1">
                    <h5 className="conversation-name">
                      <p className="mb-0 ctext-content">{content}</p>
                      {/*  <Day />  */}
                    </h5>
                  </div>
                </div>
              </div>
              <Menu
                onForward={onForwardMessage}
                onDelete={onDeleteMessage}
                onReply={onClickReply}
              />

            </div>

            <div className="conversation-name">
              <small className={classnames("text-muted", "mb-0", "me-2")}>
                {new Date(date).toLocaleTimeString()}
              </small>
            </div>
          </div>
        </div>



      </li>
    </>
  );
};

export default Message;