
import AppSimpleBar from "../../../../components/AppSimpleBar";
import { Button, UncontrolledTooltip, Form, Input } from "reactstrap";
import PendingInvitationsList from "./PendingInvitationsList";

const Invitations=()=>{

return(

  <div className="tab-pan" >
  <div className="position-relative ">
      <div className="px-4 pt-4" >

          {/* */}
          <div className="d-flex align-items-start  ">
              <div className="flex-grow-1">
                  <h4 className="mb-4">Invitations</h4>
              </div>
          </div>
          {/* */}



          {/*Search Box*/}
          <Form>
              <div className="input-group mb-4">
                  <Input
                      type="text"
                      className="form-control bg-light border-0 pe-0"
                      placeholder="Search Contacts.."
                      aria-label="Search Contacts..."
                      aria-describedby="button-searchcontactsaddon"
                  //value={search || ""}
                  //onChange={(e: any) => onChangeSearch(e.target.value)}
                  />
                  <button
                      className="btn btn-light"
                      type="button"
                      id="button-searchcontactsaddon"
                  >
                      <i className="bx bx-search align-middle"></i>
                  </button>
              </div>
          </Form>
          {/*Search Box*/}



          <div className="position-relative" >
              <AppSimpleBar className="chat-message-list chat-group-list">
                  <div className="chat-message-list" >
                      <ul className="list-unstyled chat-list chat-user-list">
                          <PendingInvitationsList />
                          {console.log("invitations")}
                      </ul>
                  </div>

              </AppSimpleBar>
          </div>
      </div>
  </div>
</div>
      
)
}

export default Invitations;