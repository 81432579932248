import React from "react";
import { Tooltip, Typography, Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import InvitationDecisionButtons from "./InvitationDecisionButtons";
import Avatar from "../../../../components/Avatar";
import PendingInvitationListItemSmall from "./PendingInvitationListItemSmall";


interface FriendsListItemProps {
    id: string;
    username: string;
    email: string;
}

const PendingInvitationsListItem = ({
    id,
    username,
    email,
}: FriendsListItemProps) => {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));

    if (matches) {
        return (
            <Tooltip title={email}>
                <div style={{ width: "100%" }}>
                    <div className="d-flex align-items-center">
                        <Avatar username={username} />

                        <div className="overflow-hidden">
                            <p className="text-truncate mb-0">  {username}</p>

                        </div>


                        <InvitationDecisionButtons invitationId={id} />
                    </div>
                </div>
            </Tooltip>
        );
    } else {
        return <PendingInvitationListItemSmall username={username} invitationId={id} />;
    }
};

export default PendingInvitationsListItem;
